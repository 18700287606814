import styled from '@emotion/styled'

export const WorkTitleMobile = styled.li`
  padding-bottom: 2rem;
  line-height: 2rem;
  display: flex;
  flex-flow: column;
  a:hover {
    color: #000;
  }
`
export const MobileNomeProjecto = styled.span`
  padding-left: 1rem;
  display: block;
`
export const MobileContent = styled.div`
  @media (min-width: 781px) {
    display: none;
  }
`

export const MobileList = styled.ol`
  list-style: none;
  width: 100%;
  display: flex;
  flex-flow: column;
`
