import styled from '@emotion/styled'

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 780px) {
    display: none;
  }
`

export const WorkList = styled.nav`
  display: flex;
  flex-grow: 0;
  /* width: 300px; */
  flex-shrink: 0;
  @media (max-width: 1250px) {
    width: 33%;
  }
`

export const DynamicImage = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  max-width: 50%;
  @media (max-width: 1250px) {
    width: 100%;
  }
  @media (max-width: 1440px) {
    max-width: 725px;
  }
`

export const WorkTitle = styled.li`
  margin-bottom: 0.75rem;
  @media (max-width: 1250px) {
    margin-bottom: 0.5rem;
  }
`
